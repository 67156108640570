import React from "react"
import { StaticQuery, Link, graphql } from "gatsby"

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"

import "./index.scss"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`impact`, `indicator`, `navigating`]} />
    <div className="main">
      <div className="content">
      {/* <div style={{ maxWidth: `100px`, marginBottom: `1.45rem` }}>
        <Image src="site-icon.png" />
      </div> */}
        <h1 className="page-title">คุณต้องการสร้าง<br />ความเปลี่ยนแปลงด้านไหน</h1>

        <div class="home-teaser">
          <p>
            "คุณอยากสร้างการเปลี่ยนแปลงด้านใด?" <br/> นี่คือคำถามที่ผู้ดำเนินกิจการหรือโครงการด้านสังคมควรตอบให้ได้อย่างชัดเจนก่อนเริ่มทำงาน เพื่อจะได้ทบทวนและตรวจสอบดูว่ากิจการหรือโครงการนั้นน่าจะสร้าง "ผลลัพธ์ทางสังคม" (social impact) ที่ตอบโจทย์ของสังคมจริงหรือไม่ 
          </p>
          <p>
            Social Impact Explorer (SIE) หรือ "แบบสำรวจผลลัพธ์ทางสังคม" ถูกออกแบบมาเป็น "เครื่องมือเรียนรู้ออนไลน์" ที่จะช่วยคุณมองอย่างแจ่มชัดมากขึ้นว่า คุณอยากสร้างการเปลี่ยนแปลงด้านใด ให้กับใคร และจะนำทางไปถึงตัวชี้วัดเบื้องต้นสำหรับการติดตามและประเมินผลลัพธ์ทางสังคม
          </p>
          <p>
            มาเริ่มกันเลยดีกว่า ด้วยการคลิกสาขาที่คุณสนใจด้านล่างนี้
          </p>
        </div>

        <div className="menu">
          <ul className="menu-list">
            <StaticQuery
              query={graphql`
                query {
                  allGoogleSpreadsheetImpactIndicatorsIndex {
                    nodes {
                      permalink
                      title
                    }
                  }

                }
              `}
              render={data => data.allGoogleSpreadsheetImpactIndicatorsIndex.nodes.map(item => (
                <li className="menu-list-item" key={`menu-impact--${item.permalink}`}>
                <Link className="menu-list-item--name" to={`/impacts/${item.permalink}`}> 
                  <img src={`/media/images/${item.permalink}-landing.png`} srcSet={`/media/images/${item.permalink}-landing@2x.png 2x`} alt="icon" width="90px" />
                  <div>{item.title}</div>
                </Link>
                </li>
              ))}
            />
          </ul>
          </div>
      </div>
      
    </div>
  </Layout>
)

export default IndexPage
